import React from 'react';
import Footer from './components/Footer'
import Header from './components/Header'
import PageBanner from './components/PageBanner'
import QuoteWithoutButton from './components/QuoteWithoutButton';
import Services2 from './components/Services2'

export default function OurServices(props){
    return(
        <div>
            <title>Our Services | AGT Construction Services Ltd.</title>
            <Header></Header>
            <PageBanner pageTitle="Our Services"></PageBanner>
            <Services2></Services2>
            <QuoteWithoutButton></QuoteWithoutButton>
            <Footer></Footer>
        </div> 
    )
}