import React, { Component } from 'react' 

export class QuoteWithoutButton extends Component {  
    render() { 
        const alignItems = {
            alignItems: 'center'
        } 
        return (  
            <section className="container cta-overlap">
                <div className="text d-flex" style={alignItems}>
                <h2 className="h3">We are available to fill short lead-time requests, and long-term staffing requirements.</h2>
                </div>
          </section>
        )  
    }  
}  
  
export default QuoteWithoutButton  