import React, { Component } from 'react' 

export class Services extends Component {  
    render() {  
        return (  
            <section className="section">
                <div className="container">

                    <div className="row justify-content-center mb-5 element-animate">
                    <div className="col-md-8 text-center">
                        {/* <h2 className="text-uppercase heading border-bottom mb-4">Services</h2> */}
                        <p className="mb-3 lead">We strive to provide professional staffing and recruitment solutions to our business partners for mutual growth and success, through quality employment opportunities for our employees and recruits. At AGT Construction Services Ltd we specialize in temporary assignments, temporary to direct hire placements, and payroll services.</p>
                    </div>
                    </div>

                    <div className="row mb-5">

                    <div className="col-lg-4 col-md-6 col-12 mb-3 element-animate">
                        <div className="media d-block media-feature text-center">
                        {/* <span className="flaticon-blueprint icon"></span> */}
                        <div className="media-body">
                            <h3 className="mt-0 text-black">Temporary Assignments</h3>
                            <p>Give your organization access to a labour force that can be increased or decreased with ease. Our clients have the freedom of no long-term commitments. We help you complete your projects on schedule and on budget. AGT Construction Services can connect your business with both skilled, safety-minded construction professionals and general labourers.</p>
                            {/* <p><a href="index.html" className="btn btn-outline-primary btn-sm">Learn More</a></p> */}
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 mb-3 element-animate">
                        <div className="media d-block media-feature text-center">
                        {/* <span className="flaticon-building-1 icon"></span> */}
                        <div className="media-body">
                            <h3 className="mt-0 text-black">Temporary to Permanent Placement</h3>
                            <p>This option is gaining momentum as employers seek to try prospects before they hire them on permanently. AGT can help you take the risk out of hiring through our experience in recruiting, screening, and training. We are confident about delivering your staffing needs.</p>
                            {/* <p><a href="index.html" className="btn btn-outline-primary btn-sm">Learn More</a></p> */}
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 mb-3 element-animate">
                        <div className="media d-block media-feature text-center">
                        {/* <span className="flaticon-crane icon"></span> */}
                        <div className="media-body">
                            <h3 className="mt-0 text-black">Payroll Services</h3>
                            <p>We assume all payroll and administration costs and pay all workers we assign to your jobs. We then provide you with a summary and invoice. All staff we provide are covered under our insurance. Our employment agency understands the construction industry inside and out and we are committed to finding suitable placement in your business and provide recruitment solutions.</p>
                            {/* <p><a href="index.html" className="btn btn-outline-primary btn-sm">Learn More</a></p> */}
                        </div>
                        </div>
                    </div>



                    
                    </div>
                    
                </div>
            </section>
        )  
    }  
}  
  
export default Services  