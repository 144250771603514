import React, { Component } from 'react' 

export class Features extends Component {  
    render() {  
        return (  
            <section className="section">
                <div className="container">

                    <div className="row justify-content-center mb-5 element-animate">
                        <div className="col-md-8 text-center">
                            <h2 className="text-uppercase heading border-bottom mb-4">Our Core Values</h2>
                            {/* <p className="mb-3 lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi unde impedit, necessitatibus, soluta sit quam minima expedita atque corrupti reiciendis.</p> */}
                        </div>
                    </div>

                    <div className="row mb-5">

                    <div className="col-lg-4 col-md-6 col-12 mb-3 element-animate">
                        <div className="media d-block media-feature text-center">
                        {/* <span className="flaticon-blueprint icon"></span> */}
                        <div className="media-body">
                            <h3 className="mt-0 text-black">Continuous Improvement</h3>
                            <p>We provide ongoing training opportunities to maximize potential and skill set and seek out individuals who are driven by continuously improving themselves and are enthusiastic about growing with us at AGT Construction Services Ltd. </p>
                            {/* <p><a href="index.html" className="btn btn-outline-primary btn-sm">Learn More</a></p> */}
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 mb-3 element-animate">
                        <div className="media d-block media-feature text-center">
                        {/* <span className="flaticon-building-1 icon"></span> */}
                        <div className="media-body">
                            <h3 className="mt-0 text-black">Ownership</h3>
                            <p>Every member of our team is challenged and empowered to act with a personal sense of ownership for the success of our candidates, our clients and our company. We take pride and ownership in everything we do.</p>
                            {/* <p><a href="index.html" className="btn btn-outline-primary btn-sm">Learn More</a></p> */}
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 mb-3 element-animate">
                        <div className="media d-block media-feature text-center">
                        {/* <span className="flaticon-crane icon"></span> */}
                        <div className="media-body">
                            <h3 className="mt-0 text-black">Integrity</h3>
                            <p>Integrity is the foundation for a successful partnership with our candidates and clients. It is how we are able to provide high-quality service and maintain a positive reputation.</p>
                            {/* <p><a href="index.html" className="btn btn-outline-primary btn-sm">Learn More</a></p> */}
                        </div>
                        </div>
                    </div>



                    
                    </div>
                    {/* <div className="row justify-content-center element-animate"> 
                    <div className="col-md-4"><p><a href="services.html" className="btn btn-primary btn-block">View All Services</a></p></div>
                    </div> */}
                </div>
            </section>
        )  
    }  
}  
  
export default Features  