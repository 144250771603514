import React, { Component } from 'react' 

export class Footer extends Component {  
    render() {  
        const logo = {
            width: "100%"
        }
        return (  
            
            <footer className="site-footer bg-dark" role="contentinfo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h3 className="text-white">Contact Information</h3>
                            <ul className="list-unstyled footer-link">
                                <li><span className="mr-3 d-block text-primary">Address:</span><span className="text-white">500-224 West Esplanade, North Vancouver, B.C, V7M 1A4</span></li>
                                <li><span className="mr-3 d-block text-primary">Phone (office):</span><span className="text-white">(604) 924-5574</span></li>
                                <li><span className="mr-3 d-block text-primary">Mobile:</span><span className="text-white">(778) 239-2662</span></li>
                                <li><span className="mr-3 d-block text-primary">Fax:</span><span className="text-white">(604) 971-0209</span></li>
                                <li><span className="mr-3 d-block text-primary">E-mail:</span><span className="text-white">agt@agtconstructionservices.com</span><br/><span className="text-white">hr@agtconstructionservices.com</span></li>
                                <li className="text-primary">Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | AGT Construction Services LTD.</li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h3 className="text-white">Quick Links</h3>
                            <ul className="list-unstyled footer-link">
                                <li><a href="/">Home</a></li>
                                <li><a href="/employers">Employers</a></li>
                                <li><a href="/our-services">Our Services</a></li>
                                <li><a href="/job-seekers">Jobseekers</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h3 className="text-white">Social Media</h3>
                            <ul className="list-unstyled footer-link">
                                <li><a href="https://www.facebook.com/agtconstructionservicesltd" target="_blank"><span className="fa fa-facebook"></span> Facebook</a></li>
                                <li><a href="https://www.instagram.com/agtconstructionservices/" target="_blank"><span className="fa fa-instagram"></span> Instagram</a></li>
                            </ul>

                            {/* <a href="https://www.bbb.org/ca/bc/north-vancouver/profile/construction/agt-construction-services-ltd-0037-2414846/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-mbc.bbb.org/seals/blue-seal-63-134-bbb-2414846.png" style={{border: 0}} alt="AGT Construction Services Ltd. BBB Business Review" /></a> */}
                        </div>
                    </div>
                </div>
            </footer>
        )  
    }  
}  
  
export default Footer  