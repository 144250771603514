import React, { Component } from 'react' 
import Swal from 'sweetalert2';

export class EmployerForm extends Component { 
    
    submitApplication(){
        let companyName = document.getElementById('companyName').value;
        let companyAddress = document.getElementById('companyAddress').value;
        let fname = document.getElementById('fname').value;
        let lname = document.getElementById('lname').value;
        let phone = document.getElementById('phone').value;
        let email = document.getElementById('email').value;
        let siteAddress = document.getElementById('siteAddress').value;
        let siteCity = document.getElementById('siteCity').value;
        let postalCode = document.getElementById('postalCode').value;
        let siteFname = document.getElementById('siteFname').value;
        let siteLname = document.getElementById('siteLname').value;
        let sitePhone = document.getElementById('sitePhone').value;
        let dateRequired = document.getElementById('dateRequired').value;
        let startTime = document.getElementById('startTime').value;
        let numWorkers = document.getElementById('numWorkers').value;
        let skillsRequired = document.getElementById('skillsRequired').value;
        let description = document.getElementById('description').value;

        if(companyName!=='' && companyAddress!=='' && fname!=='' && lname!=='' && phone!=='' && email!=='' && siteAddress!=='' && siteCity!=='' && postalCode!=='' && dateRequired!==''  && startTime!==''  && numWorkers!=='' && skillsRequired!=='' && description!==''){
            const apiUrl = 'https://email.agtconstructionservices.com/api.php';
            let data = {
                message: '<h2>Company Details</h2>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Company Name</p>'+
                            '<p>'+companyName+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Company Address</p>'+
                            '<p>'+companyAddress+'</p>'+
                        '</div>'+
                        '<h2>Contact Person</h2>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">First Name</p>'+
                            '<p>'+fname+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Last Name</p>'+
                            '<p>'+lname+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Phone</p>'+
                            '<p>'+phone+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Email Address</p>'+
                            '<p>'+email+'</p>'+
                        '</div>'+
                        '<h2>Site Address</h2>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Street Address</p>'+
                            '<p>'+siteAddress+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">City</p>'+
                            '<p>'+siteCity+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Postal Code</p>'+
                            '<p>'+postalCode+'</p>'+
                        '</div>'+
                        '<h2>Site Contact Person (if different)</h2>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">First Name</p>'+
                            '<p>'+siteFname+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Last Name</p>'+
                            '<p>'+siteLname+'</p>'+
                        '</div>'+
                        '<h2>Site Contact Phone (if different)</h2>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Phone Number</p>'+
                            '<p>'+sitePhone+'</p>'+
                        '</div>'+
                        '<h2>Relevant Information</h2>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Date Required</p>'+
                            '<p>'+dateRequired+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Start Time</p>'+
                            '<p>'+startTime+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Number of Workers</p>'+
                            '<p>'+numWorkers+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Skills Required for Project</p>'+
                            '<p>'+skillsRequired+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Brief Description of your Project</p>'+
                            '<p>'+description+'</p>'+
                        '</div>',
                to: 'agt@agtconstructionservices.com, ahsankamran996@gmail.com',
                subject: fname+' '+lname+' - Employer Application'
            }
            fetch(apiUrl, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
            .then(res => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Your response has been submitted successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                  }).then(()=>{
                    // window.location.reload(false);
                  })
            });
        } else{
            Swal.fire({
                title: 'Error!',
                text: 'Please fill all fields!',
                icon: 'error',
                confirmButtonText: 'OK'
              })
        }
    }

    render() { 

        return (  
            <section className="section">
                <div className="container">
                    <div className="row justify-content-center mb-5 element-animate">
                        <div className="col-md-8 text-center">
                            <p className="mb-3 lead">Please provide your contact information below to request workers for your construction project. We look forward to providing solutions for your staffing requirements!</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <form method="post">
                                <div className="row">
                                    <p className='col-md-12 font-weight-bold'>Company Details</p>
                                    <div className="col-md-6 form-group">
                                        <label>Company Name</label>
                                        <input type="text" className="form-control form-control-lg" id="companyName"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Company Address</label>
                                        <input type="text" className="form-control form-control-lg" id="companyAddress"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <p className='col-md-12 font-weight-bold'>Contact Person</p>
                                    <div className="col-md-6 form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control form-control-lg" id="fname"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control form-control-lg" id="lname"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Phone</label>
                                        <input type="text" className="form-control form-control-lg" id="phone"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Email Address</label>
                                        <input type="text" className="form-control form-control-lg" id="email"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <p className='col-md-12 font-weight-bold'>Site Address</p>
                                    <div className="col-md-12 form-group">
                                        <label>Street Address</label>
                                        <input type="text" className="form-control form-control-lg" id="siteAddress"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>City</label>
                                        <input type="text" className="form-control form-control-lg" id="siteCity"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Postal Code</label>
                                        <input type="text" className="form-control form-control-lg" id="postalCode"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <p className='col-md-12 font-weight-bold'>Site Contact Person (if different)</p>
                                    <div className="col-md-6 form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control form-control-lg" id="siteFname"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control form-control-lg" id="siteLname"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <p className='col-md-12 font-weight-bold'>Site Contact Phone (if different)</p>
                                    <div className="col-md-6 form-group">
                                        <label>Phone Number</label>
                                        <input type="text" className="form-control form-control-lg" id="sitePhone"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <p className='col-md-12 font-weight-bold'>Relevant Information</p>
                                    <div className="col-md-6 form-group">
                                        <label>Date Required</label>
                                        <input type="date" className="form-control form-control-lg" id="dateRequired"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Start Time</label>
                                        <input type="text" className="form-control form-control-lg" id="startTime"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Number of Workers</label>
                                        <input type="text" className="form-control form-control-lg" id="numWorkers"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Skills Required for Project</label>
                                        <input type="text" className="form-control form-control-lg" id="skillsRequired"/>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Brief Description of your Project</label>
                                        <textarea type="text" className="form-control form-control-lg" id="description" rows='5'></textarea>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 form-group">
                                    <input type="button" onClick={this.submitApplication} value="Submit" className="btn btn-primary btn-lg btn-block"/>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default EmployerForm