import React, { Component } from 'react' 

export class Quote extends Component {  
    render() { 
        const alignItems = {
            alignItems: 'center'
        } 
        return (  
            <section className="container cta-overlap">
                <div className="text d-flex" style={alignItems}>
                <h2 className="h3">If this seems like a role suitable for yourself or someone you know please click on apply button.</h2>
                <div className="ml-auto btn-wrap">
                    <a href="get-quote.html" className="btn-cta btn btn-outline-white">Apply Now</a>
                </div>
                </div>
          </section>
        )  
    }  
}  
  
export default Quote  