import React, { Component } from 'react' 
import EmployerForm from './components/EmployerForm'
import Footer from './components/Footer'
import Header from './components/Header'
import PageBanner from './components/PageBanner'
import QuoteWithoutButton from './components/QuoteWithoutButton';
import Quote2 from './components/Quote2'
import Services from './components/Services'


export class Employers extends Component {  
    render() {  
        return (
            <div>
                <title>Employers | AGT Construction Services Ltd.</title>
                <Header></Header>
               <PageBanner pageTitle="Employers"></PageBanner>
               <Services></Services>
               <Quote2></Quote2>
               <EmployerForm></EmployerForm>
               <QuoteWithoutButton></QuoteWithoutButton>
               <Footer></Footer>
            </div>  
        )  
    }  
}  
  
export default Employers  