import React, { Component } from 'react' 
import Safety from './Safety'
import Swal from 'sweetalert2'

export class JobseekersForm extends Component {  

    submitApplication(){
        let firstName = document.getElementById('fname').value;
        let lastName = document.getElementById('lname').value;
        let email = document.getElementById('email').value;
        let phone = document.getElementById('phone').value;
        let city = document.getElementById('city').value;
        let dob = document.getElementById('dob').value;
        let message = document.getElementById('message').value;
        let refer = document.getElementById('refer').value;

        if(firstName!=='' && lastName!=='' && email!=='' && phone!=='' && city!=='' && dob!=='' && message!=='' && refer!==''){
            const apiUrl = 'https://email.agtconstructionservices.com/api.php';
            let data = {
                message: '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">First Name</p>'+
                            '<p>'+firstName+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Last Name</p>'+
                            '<p>'+lastName+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Email</p>'+
                            '<p>'+email+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Phone</p>'+
                            '<p>'+phone+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">What city do you live in?</p>'+
                            '<p>'+city+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Date of Birth</p>'+
                            '<p>'+dob+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">Work Experience / Tickets Held / Availability</p>'+
                            '<p>'+message+'</p>'+
                        '</div>'+
                        '<div style="border: 1px solid #ccc; padding: 10px; border-radius: 10px; margin-bottom: 10px;">'+
                            '<p style="font-weight: bold; margin-bottom: 0">How did you hear about us?</p>'+
                            '<p>'+refer+'</p>'+
                        '</div>',
                to: 'agt@agtconstructionservices.com, ahsankamran996@gmail.com',
                subject: firstName+' '+lastName+' - Jobseeker Application'
            }
            fetch(apiUrl, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
            .then(res => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Your response has been submitted successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                  }).then(()=>{
                    window.location.reload(false);
                  })
            });
        } else{
            Swal.fire({
                title: 'Error!',
                text: 'Please fill all fields!',
                icon: 'error',
                confirmButtonText: 'OK'
              })
        }
    }

    render() { 

        return (  
            <section class="section">
                <div class="container">
                    <div className="row justify-content-center mb-5 element-animate">
                        <div className="col-md-10 text-center">
                            {/* <h2 className="text-uppercase heading border-bottom mb-4">Apply Now</h2> */}
                            {/* We encourage courses at our expense to provide our employees the right match with each particular job.  */}
                            <p className="mb-3 lead">We encourage courses at our expense to provide our employees the right match with each particular job. We surround ourselves with positive, driven, results-orientated people who love a good challenge. We are seeking hardworking individuals who strive to produce high quality work on time, regardless of the task. They recognize that everything they do can impact the organization, their colleagues and business outcomes. With this kind of outlook, you can chart your own career and your potential growth. Learning and development are critical to our success. Whether you join AGT as an expert in your field or want to grow from within we are always looking for great individuals to add to our team. </p>

                        </div>
                    </div>
                </div>

                <Safety></Safety>

                <section className="container cta-overlap mb-5">
                    <div className="text d-flex">
                        <h2 className="h3">If this seems like a role suitable for yourself or someone you know please fill out the following form & send your updated resume at agt@agtconstructionservices.com</h2>
                    </div>
                </section>

                <div class="container">
                    <div className="row justify-content-center mb-5 element-animate"> 

                        <div className="col-md-10">
                            <form>
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control form-control-lg" id="fname"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control form-control-lg" id="lname"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label>Email</label>
                                        <input type="email" id="email" className="form-control form-control-lg"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Phone</label>
                                        <input type="text" id="phone" className="form-control form-control-lg"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label>What city do you live in?</label>
                                        <input type="text" id="city" className="form-control form-control-lg"/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Date of Birth</label>
                                        <input type="date" id="dob" className="form-control form-control-lg"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                    <label>Work Experience / Tickets Held / Availability</label>
                                    <textarea name="message" id="message" className="form-control form-control-lg" cols="30" rows="8"></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                    <label>How did you hear about us?</label>
                                    <textarea name="reference" id="refer" className="form-control form-control-lg" cols="30" rows="8"></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                    <input type="button" onClick={this.submitApplication} value="Submit Application" className="btn btn-primary btn-lg btn-block"/>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        )  
    }  
}  
  
export default JobseekersForm  