import React, { Component } from 'react' 

export class About extends Component {  
    render() {  
        return (  
            <section className="section bg-light">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-md-6 pr-lg-5 mb-5 mb-md-0  element-animate">
                        <div className="pr-lg-5">
                            <h2 className="text-uppercase heading border-bottom mb-4 text-left">Bringing solutions to your <br/>Staffing Requirements</h2>
                            <p>Operating throughout Vancouver and the surrounding areas we are able to provide exceptional staffing solutions which specialize in quick and reliable on-demand requests through our highly skilled workers. Our staffing services help you to lower costs, increase flexibility and focus on your goals.</p> <p>We are your best representation for finding jobs in construction. We focus on a number of skilled trades positions such as labourers, carpenters, concrete and much more at competitive rates. Keep projects on-track with off-site talent. AGT Construction Services saves you time and money by taking care of recruiting, hiring, training, and payroll so you can focus on getting the job done.</p>
                        </div>
                        </div>
                    <div className="col-md-6 text-center element-animate">
                        <img src="assets/img/logo.png" alt="" className="img-fluid"/>
                        {/* <p className="text-primary font-weight-bold text-center">Bringing Solutions to Your Staffing Requirements</p> */}
                    </div>
                    </div>
                </div>
            </section>
        )  
    }  
}  
  
export default About  