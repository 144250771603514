import React, { Component } from 'react' 

export class Safety extends Component {  
    render() {  
        const height = {
            height: '200px',
            alignItems: 'center'
        }
        return (  
            <section className="section border-t">
                <div className="container">
                    <div className="row justify-content-center mb-5 element-animate">
                        <div className="col-md-8 text-center">
                            <h2 className="text-uppercase heading border-bottom mb-4">Safety</h2>
                            <p className="mb-3 lead">Safety of our employees is the number one priority for AGT Construction Services Ltd. We believe that safety starts with you. We want to make sure you understand your rights and responsibilities on the job site. At AGT Construction Services we believe that proper training will help employees feel good about working for our company. Healthy employees who feel safe and comfortable in their work environment will work more productively and is the reason we are able to maintain our high employee retention rate and reputation.</p>
                        </div>
                        
                    </div>

                    <div className="row mb-5">

                        <div className="col-lg-4 col-md-6 col-12 mb-3 element-animate">
                            <div className="media media-feature text-center" style={height}>
                                <div className="media-body">
                                    <h3 className="mt-0 text-black">Meets Workers Compensation Board standards.</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mb-3 element-animate">
                            <div className="media media-feature text-center" style={height}>
                                <div className="media-body">
                                    <h3 className="mt-0 text-black">Adheres to Worksafe BC standards.</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mb-3 element-animate">
                            <div className="media media-feature text-center" style={height}>
                                <div className="media-body">
                                    <h3 className="mt-0 text-black">Workplace safety training meet Canadian Standards Association (CSA) requirements and Provincial Occupational Health and Safety (OHS) Standards.</h3>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )  
    }  
}  
  
export default Safety  