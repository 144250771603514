
import Home from './Home'
import {
  Switch,
  Route
} from "react-router-dom";
import Employers from './Employers';
import Jobseekers from './Jobseekers';
import OurServices from './OurServices';
import HttpsRedirect from 'react-https-redirect';
function App() {
  return (
    <HttpsRedirect>
    <Switch>
      <Route path="/" component={Home} exact/>
      <Route path="/employers" component={Employers} exact />
      <Route path="/job-seekers" component={Jobseekers} exact />
      <Route path="/our-services" component={OurServices} exact />
    </Switch>
    </HttpsRedirect>
  );
}

export default App;
