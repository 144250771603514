import React, { Component } from 'react' 
import Footer from './components/Footer'
import Header from './components/Header'
import JobseekersForm from './components/JobseekersForm'
import PageBanner from './components/PageBanner'
import Quote from './components/Quote'
import QuoteWithoutButton from './components/QuoteWithoutButton'
import Safety from './components/Safety'

export class Jobseekers extends Component {  
    render() {  
        return (  
            <div>
                <title>Jobseekers | AGT Construction Services Ltd.</title>
                <Header></Header>
                <PageBanner pageTitle="Jobseekers"></PageBanner>
                <JobseekersForm></JobseekersForm>
                {/* <Safety></Safety> */}
                <QuoteWithoutButton></QuoteWithoutButton>
                <Footer></Footer>
            </div>
        )
    }
}

export default Jobseekers  