import React, { Component } from 'react' 
import About from './components/About'
import Footer from './components/Footer'
import Header from './components/Header'
// import Slider from './components/Slider';

// import { Testimonials } from './components/Testimonials';
import Features from './components/Features';
import QuoteWithoutButton from './components/QuoteWithoutButton';
import Video from './components/Video';

export class Home extends Component {  
    render() {  
        return (
            <div>
                <title>Home | AGT Construction Services Ltd.</title>
                <Header></Header>
                {/* <Slider></Slider> */}
                <Video></Video>
                <About></About>
                <Features></Features>
                {/* <Projects></Projects> */}
                {/* <Services></Services> */}
                {/* <Testimonials></Testimonials> */}
                <QuoteWithoutButton></QuoteWithoutButton>
                <Footer></Footer>
            </div>  
        )  
    }  
}  
  
export default Home  