import React, { Component } from 'react' 

export class Video extends Component {  
    render() {  
        let style = {
          position: 'relative',
          width: '100%',
          // minHeight: '100%'
        }
        return (  
          <div>
            <video autoplay="true" muted loop id="video" style={style}>
              <source src="assets/videos/video.mp4" type="video/mp4" />
            </video>
          </div>
        )  
    }  
}  
  
export default Video;  