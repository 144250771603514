import React, { Component } from 'react' 

export class Quote2 extends Component {  
    render() { 
        const alignItems = {
            alignItems: 'center'
        } 
        return (  
            <section className="container cta-overlap mb-5">
                <div className="text d-flex" style={alignItems}>
                    <h2 className="h5">At AGT Construction Services we believe in the importance of establishing trusted long term relationships with our partner companies and clients; for both job seekers and employers. AGT partners with your company to provide you with project based skilled trades and labourers ready, willing and able to deliver top-notch job performance for you.</h2>
                </div>
            </section>
        )  
    }  
}  
  
export default Quote2  