import React, { Component } from 'react' 
import { Link } from 'react-router-dom'

export class Header extends Component {  
    render() {  
        const logo = {
            width: '400px'
        }
        const mini = {
            width: '100px'
        }
        return (  
            <header role="banner">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                    <a className="navbar-brand position-absolute d-none d-md-block d-lg-block d-xl-block" href="/">
                        <img src='assets/img/logo-2.png' alt='logo' style={logo}/>
                    </a>
                    <a className="navbar-brand position-absolute d-block d-md-none" href="/">
                        <img src='assets/img/agt-logo-mini.png' alt='logo' style={mini}/>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample05" aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse position-relative" id="navbarsExample05">
                        <ul className="navbar-nav ml-auto d-flex align-items-center">
                        <li className="nav-item">
                            <a className="nav-link active" href="/">Home</a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link active" href="/employers">Employers</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="/our-services">Our Services</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="/job-seekers">Job Seekers</a>
                        </li>

                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="services.html" id="dropdown04" data-toggle="dropdown">Services</a>
                            <div className="dropdown-menu" aria-labelledby="dropdown04">
                            <a className="dropdown-item" href="services.html">Pre-Construction</a>
                            <a className="dropdown-item" href="services.html">General Construction</a>
                            <a className="dropdown-item" href="services.html">House Renovation</a>
                            <a className="dropdown-item" href="services.html">Plumbing</a>
                            </div>

                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="works.html">Projects</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="news.html">News</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="about.html">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="contact.html">Contact</a>
                        </li>
                        <li className="nav-item cta-btn2">
                            <a className="nav-link" href="get-quote.html">
                            <span className="d-inline-block px-4 py-2 border">Quotation</span>
                            </a>
                        </li> */}
                        </ul>
                    </div>
                    </div>
                </nav>
            </header>
        )  
    }  
}  
  
export default Header  