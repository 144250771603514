import React, { Component } from 'react' 

export class PageBanner extends Component {  
    render() { 
        const banner = {
            backgroundImage: "url(assets/img/slider-1.jpg)",
            backgroundPosition: 'center bottom'
        } 
        const overlay = {
            background: "rgba(0,0,0,0.5)",
            maxWidth: "100%"
        }
        return (  
            <section className="inner-page">
                <div className="slider-item" style={banner}>
                    
                    <div className="container py-5" style={overlay}> 
                    <div className="row slider-text align-items-center justify-content-center text-center">
                        <div className="col-md-7 col-sm-12 element-animate">
                        <h1 className="text-white">{this.props.pageTitle}</h1>
                        </div>
                    </div>
                    </div>

                </div>
            </section>
        )  
    }  
}  
  
export default PageBanner  