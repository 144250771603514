import React, { Component } from 'react' 

export class Services2 extends Component {  
    render() { 
        // const imgWidth = {
        //     width: '300px'
        // } 
        const listStyle = {
            listStylePosition: 'inside !important'
        }
        return (  
            <section className="section">
                <div className="container">
                    <h2 className="text-uppercase heading border-bottom mb-4 text-center">Our Services</h2>
                    <div className="row mb-5 justify-content-center">

                       <div className="col-md-3 text-center">
                            <ul className="list-unstyled check">
                                <li>Skilled Carpenter</li>
                                <li>Carpenter Helper</li>
                            </ul>
                        </div> 
                        
                        <div className="col-md-3 text-center">
                            <ul className="list-unstyled check">
                                <li>Labour Foreman</li>
                                <li>Skilled Labour</li>
                                <li>General Labour</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row mb-5 justify-content-center">

                        <div className="col-md-3 text-center">
                            <ul className="list-unstyled check">
                                <li>CSO</li>
                                <li>First Aid Level 2/3</li>
                                <li>TCP / Flagger</li>
                            </ul>
                        </div>

                        <div className="col-md-3 text-center">
                            <ul className="list-unstyled check">
                                <li>Construction Cleaner</li>
                                <li>Final Cleaner</li>
                            </ul>
                        </div>

                    </div>

                    <div className="row mb-5 justify-content-center">
                        <div className="col-md-3 text-center">
                            <ul className="list-unstyled check">
                                <li>Hoist Operator</li>
                                <li>Elevator Operator</li>
                                <li>Skid Steer</li>
                                <li>Forklift</li>
                                <li>Zoom Boom / Scissor Lift</li>
                            </ul>
                        </div>

                        <div className="col-md-3 text-center">
                            <ul className="list-unstyled check">
                                <li>Deficiency</li>
                                <li>Painter</li>
                                <li>Drywall</li>
                            </ul>
                        </div>
                    </div>
                
                </div>
            </section>
        )  
    }  
}  
  
export default Services2  